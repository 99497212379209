import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { AppSession } from "@interface/interface.app-session";
import { Pagination } from "@interface/interface.pagination";
import { filter } from "rxjs/operators";
import { Encryption } from "@utility/utility.encryption";
import { Application } from "@utility/utility.application";
import { PackageService } from "@services/admin/package.service";
import { TranslateService } from "@ngx-translate/core";
import { Notify } from "@utility/utility.notify";
import { MessageType, Module } from "@app.enum";
import { ImReportService } from "@services/im/im-report.service";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import * as ExcelJS from "exceljs/dist/exceljs";
import { ShiftService } from "@services/admin/shift.service";
import { IncidentService } from "@services/im/incident.service";
import { IMIncidentTypeService } from "@services/im/im-incidenttype.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DaterangepickerDirective } from "ngx-daterangepicker-material";
import { isNumber } from "lodash";
import { IMIncidentClassificationService } from "@services/im/im-incidentclassification.service";
import { Subscription } from "rxjs";
import { GenericDataService } from "@services/generic-data.service";
import { FilterHistoryService } from "@services/admin/filter-history.service";
import { ApiUtility } from "@utility/utility.api-utility";
const _clone = (d) => JSON.parse(JSON.stringify(d));

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    "(window:resize)": "onResize($event)",
  },
})
export class ReportComponent implements OnInit {
  @ViewChild('table') datatable: DatatableComponent;
  @ViewChild('table', { read: ElementRef }) datatableElement: ElementRef | undefined;
  ShowList = [

    { ShowListId: 'Last_7_Days', name: 'Last 7 Days' },
    { ShowListId: 'Last_30_Days', name: 'Last 30 Days' },
    { ShowListId: 'This_month', name: 'This month' },
    { ShowListId: 'Last_month', name: 'Last_month' },
    { ShowListId: 'Last_3_Months', name: 'Last 3 Months' },
    { ShowListId: 'Last_6_Months', name: 'Last 6 Months' },
    { ShowListId: 'This_Quarter', name: 'This quarter' },
    { ShowListId: 'Last_Quarter', name: 'Last quarter' },
    { ShowListId: 'This_Year', name: 'This Year' }
  ];
  selectedDate: any;
  lastSevenDays: Date;
  selectedValue: string = "Last_7_Days";
  @Input() dashboardUrl = "";
  @ViewChild("viewDetail") viewDetail: TemplateRef<any>;
  @ViewChild("showTotalRow") showTotalRow: TemplateRef<any>;
  @ViewChild("htmlViewDetail") htmlViewDetail: TemplateRef<any>;
  tableData: any;
  session: AppSession;
  isApplyFilter: boolean = false;
  entryFormClass: any;
  maxDate = moment();
  selectedFilterOption2 = "Location Wise";
  total = 0;
  incidentListForm: FormGroup;
  objAarray: any[] = [];
  //demodata: "this is demodata";
  rows = [];
  ColumnMode = ColumnMode;
  columns = [];
  allColumns = [];
  level = 0;
  date = new Date();
  lineList: any;
  PositionList: any;
  DepartmentList: any;
  ClusterList: any;
  params: any;
  ageGroupList: any;
  OperationalCauseList: any;
  availablecolumn = [];
  availablecolumnKeys: any[] = [];

  // joinedcolumn = [];
  column: any;
  row: any;
  statusWiseData: any;
  Title: any;
  currentUrl: any;

  //filter
  isfilterShown: any;
  masterId: any;
  shift: any;

  IncidentCatagoryList: Array<any> = [];
  incidentTypeList: any[];
  GICList: any;
  AreaList: any;
  LocationList: any;
  IncidentNatureList: any;
  PSMList: any;
  locations1: Array<any>;
  clicks = {};
  filteredkeys = [];
  today = new Date();
  // d = new Date().toISOString().substring(0, 16);
  d = new Date(new Date().getFullYear(), 0, 1);
  checkclick = {};

  //end filter
  innerHeight = 300;
  isPDFAvailable: any;
  isExcelAvailable: any;
  location: any;
  age: any;

  ListofIncident = [];
  gicCount = [];
  page = new Pagination();

  // activity state
  //public loading: boolean = false;
  loading: boolean = false;
  finalrowData: any;
  //Date Picker
  dateFormat: string = _$config.dateFormat;

  dateRangeConfig = Object.assign(_clone(_$config.datepickerConfig), {
    maxDate: this.today,
  });

 
  

  modelData: any;
  format: string;
  previousUrl: any;


  onSelectionChange(event: any) {
    this.selectedValue = event.ShowListId; // Assuming event contains the selected value from ng-select

    if (this.selectedValue === "Last_7_Days") {
      this.incidentListForm.controls.selected.patchValue({
        startDate: moment().subtract(7, "days"),
        endDate: moment(),
      });
      this.incidentListForm.get("selected").updateValueAndValidity();
    }

    if (this.selectedValue === "This_month") {
      this.incidentListForm.controls.selected.patchValue({
        startDate: moment().date(1),
        endDate: moment(),
      });
      this.incidentListForm.get("selected").updateValueAndValidity();
      // console.log('current month:', currentMonth);
    }
    if (this.selectedValue === "Last_month") {
      const startOfLastMonth = moment().subtract(1, "month").startOf("month");
      const endOfLastMonth = moment().subtract(1, "month").endOf("month");
      this.incidentListForm.controls.selected.patchValue({
        startDate: startOfLastMonth,
        endDate: endOfLastMonth,
      });
      this.incidentListForm.get("selected").updateValueAndValidity();
    }

    if (this.selectedValue === "Last_30_Days") {
      this.incidentListForm.controls.selected.patchValue({
        startDate: moment().subtract(30, "days"),
        endDate: moment(),
      });
      this.incidentListForm.get("selected").updateValueAndValidity();
    }

    // if (this.selectedValue === 'This_Quarter') {
    //   const currentQuarter = moment().quarter();
    //   const startOfQuarter = moment().quarter(currentQuarter).startOf('quarter');
    //   const endOfQuarter = moment().quarter(currentQuarter).endOf('quarter');
    //   this.incidentListForm.controls.selected.patchValue({
    //     startDate: startOfQuarter.subtract(30, 'days'),
    //     endDate: endOfQuarter
    //   })
    //   this.incidentListForm.get("selected").updateValueAndValidity();
    // }

    if (this.selectedValue === "This_Quarter") {
      const currentQuarter = moment().quarter();
      const startOfQuarter = moment()
        .quarter(currentQuarter)
        .startOf("quarter");
      const endOfQuarter = moment().quarter(currentQuarter).endOf("quarter");

      this.incidentListForm.controls.selected.patchValue({
        startDate: startOfQuarter,
        endDate: endOfQuarter,
      });

      this.incidentListForm.get("selected").updateValueAndValidity();
    }

    if (this.selectedValue === "This_Year") {
      this.incidentListForm.controls.selected.patchValue({
        startDate: moment().subtract(365, "days"),
        endDate: moment(),
      });
      this.incidentListForm.get("selected").updateValueAndValidity();
      // Get the current year
      const currentYear = moment().year();
      console.log("Current Year:", currentYear);
    }

    // if(this.selectedValue === 'Last_Quarter'){
    //   const currentQuarter = moment().quarter();
    //   const lastQuarter = currentQuarter - 1 < 1 ? 3 : currentQuarter - 1; // Determine last quarter
    //   const startOfLastQuarter = moment().quarter(lastQuarter).startOf('quarter');
    //   const endOfLastQuarter = moment().quarter(lastQuarter).endOf('quarter');
    //   this.incidentListForm.controls.selected.patchValue({
    //     startDate: startOfLastQuarter.subtract(30, 'days'),
    //     endDate: endOfLastQuarter
    //   })
    //   this.incidentListForm.get("selected").updateValueAndValidity();
    // }

    if (this.selectedValue === "Last_Quarter") {
      const currentQuarter = moment().quarter();
      const lastQuarter = currentQuarter - 1 < 1 ? 4 : currentQuarter - 1; // Determine last quarter
      const startOfLastQuarter = moment()
        .quarter(lastQuarter)
        .startOf("quarter");
      const endOfLastQuarter = moment().quarter(lastQuarter).endOf("quarter");

      this.incidentListForm.controls.selected.patchValue({
        startDate: startOfLastQuarter,
        endDate: endOfLastQuarter,
      });

      this.incidentListForm.get("selected").updateValueAndValidity();
    }

    if (this.selectedValue === "Last_3_Months") {
      const currentQuarter = moment().quarter();
      const lastQuarter = currentQuarter - 1 < 1 ? 4 : currentQuarter - 1; // Determine last quarter
      const currentMonth = moment().month();
      const lastThreeMonthsStart = moment()
        .subtract(3, "months")
        .startOf("month");
      const lastThreeMonthsEnd = moment().subtract(1, "months").endOf("month");
      this.incidentListForm.controls.selected.patchValue({
        startDate: lastThreeMonthsStart,
        endDate: lastThreeMonthsEnd,
      });
      this.incidentListForm.get("selected").updateValueAndValidity();
    }

    if (this.selectedValue === "Last_6_Months") {
      const lastSixMonthsStart = moment()
        .subtract(6, "months")
        .startOf("month");
      const lastSixMonthsEnd = moment().subtract(1, "months").endOf("month");
      this.incidentListForm.controls.selected.patchValue({
        startDate: lastSixMonthsStart,
        endDate: lastSixMonthsEnd,
      });
      this.incidentListForm.get("selected").updateValueAndValidity();
    } else {
      this.selectedDate = null;
      this.lastSevenDays = null;
    }
  }

  modelDatarecommendation: any;
  modalRef: BsModalRef;
  @ViewChild("template") template: TemplateRef<any>;
  @ViewChild("tableTemplate") tableTemplate: TemplateRef<any>;
  // @ViewChild('openUpdateLogoPopup') openUpdateLogoPopup: BsModalRef;
  @ViewChild(DaterangepickerDirective)
  pickerDirective: DaterangepickerDirective;

  value: number = 40;
  highValue: number = 60;
  options: any = {
    floor: 0,
    ceil: 100,
  };
  filterNames: any = [];
  totalData: any;
  showCummulativeSearch: boolean;
  showSubCummulativeSearch: any = [];
  totalArr: any = [];
  functionlist: any;
  cummulativeFilterNames: any = [];
  dateRange: any = _$config.dateRange;
  currentUser: any;
  note: any;
  incidentStatusList: any;
  emptypelist: any = [];
  IncidentClassificationList: any;
  loadedFilterOnce: boolean = false;
  employeeList: any;
  private subscriptions = new Subscription();
  serverSidePagination: string = "Yes";
  reportTypeId: any;
  toastvisible: boolean = false;

  constructor(
    private router: Router,
    public fb: FormBuilder,
    private _router: ActivatedRoute,
    public _package: PackageService,
    public _imreportService: ImReportService,
    private translate: TranslateService,
    public modalService: BsModalService,
    private shiftList: ShiftService,
    private _incidentData: IncidentService,
    private _incidentTypeService: IMIncidentTypeService,
    private _incidentClassification: IMIncidentClassificationService,
    private generic: GenericDataService,
    private filterService:FilterHistoryService
  ) {
    this.session = Application.GetSession();
    this.location = this.session.LocationId;
    this.incidentListForm = this.createForm();
    this.setScreenWidth($(window).width());
    router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (e["url"].includes("?")) {
          let url = e["url"].split("?");
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          console.log(this.currentUrl);
          this.currentUrl = url[0];
          this.previousUrl = this.currentUrl;
        } else {
          this.currentUrl = e["url"];
          this.previousUrl = this.currentUrl;
        }
      });
    this._router.queryParams.subscribe((params) => {
      if (params.q) {
        this.params = Encryption.GetQueryParam(params.q);
        console.log(this.params);
        for (const [key, value] of Object.entries(this.params)) {
          if (typeof value == "string") {
            if (value.includes("[")) {
              this.params[key] = value.slice(1, -1);
            }
          }
        }
        this.incidentListForm.patchValue(this.params);
        if (this.params.fromDate && this.params.toDate) {
          // link formet of date is string
          this.incidentListForm.controls.selected.patchValue({
            startDate: moment(this.params.fromDate),
            endDate: moment(this.params.toDate),
          });
        }
        console.log(this.incidentListForm);
        this.incidentListForm.updateValueAndValidity();
      } else if (params) {
        this.params = params;
        if (this.params) {
          if (!!this.params.FromDate && !!this.params.ToDate) {
            this.incidentListForm.controls.selected.patchValue({
              startDate: moment(this.params.fromDate),
              toDate: moment(this.params.toDate),
            });
            this.incidentListForm.controls.selected.updateValueAndValidity();
          }
        }
        this.incidentListForm.patchValue(this.params);
        // this.incidentListForm.controls.selected.value.startDate = new Date(this.params.FromDate)
        // this.incidentListForm.controls.selected.value.endDate = new Date(this.params.ToDate)
        this.incidentListForm.updateValueAndValidity();
      }
    });
    this.currentUser = JSON.parse(atob(localStorage.getItem("currentUser")));
    this.initList();
  }

  ngOnInit() {
    this.employeeList = this.generic.EmployeeList;
    this.subscriptions.add(
      this.generic.EmployeeList.subscribe((res: any) => {
        if (res !== undefined && res != null) {
          this.employeeList = res;
        }
      })
    );
    if (this.dashboardUrl) {
      this.currentUrl = this.dashboardUrl;
    }
    this.setCurrentMonthYear();

    // this.setOption('IsAreaWise');
    // this.setOption('IsUserWise');
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.locations1 = this.session.Locations;
    // this.callMaster();
    // console.log(this.currentUrl);
    // this.currentUrl == "/im/listofincident-report"
    this.searchlistOfIncident(false);
    // this.getPackageFeature();
    let PackageFeature = JSON.parse(
      atob(localStorage.getItem("PackageFeature"))
    );
    this.isPDFAvailable = PackageFeature.filter(
      (x) => x.Code == "EXPORT_PDF" && x.Value == true
    );
    this.isExcelAvailable = PackageFeature.filter(
      (x) => x.Code == "EXPORT_EXCEL" && x.Value == true
    );
    this.innerHeight = window.innerHeight - 115;
    // console.log(this.difference(this.ObjClick, this.Objdata));
    // this.incidentListForm.controls["IsAreaWise"].setValue(true);
    // this.incidentListForm.controls["IsAreaWise"].updateValueAndValidity();
    // this.incidentListForm.controls["IsUserWise"].setValue(true);
    // this.incidentListForm.controls["IsUserWise"].updateValueAndValidity();
  }

  
  selectedFilterOption = "Is User Wise";
  filterPanelOptions = [
    {
      id: 1,
      value: "Is User Wise",
      isSelected: false,
      formcontrol: "IsUserWiseWithIds",
    },
    {
      id: 2,
      value: "Is Function Wise",
      isSelected: false,
      formcontrol: "IsFunctionWiseWithIds",
    },
    {
      id: 3,
      value: "Is Position Wise",
      isSelected: false,
      formcontrol: "IsPositionWiseWithIds",
    },
    {
      id: 4,
      value: "Is Department Wise",
      isSelected: false,
      formcontrol: "IsDepartmentWiseWithIds",
    },
  ];
  filterPanelOptions2 = [
    {
      id: 1,
      value: "Is Location Wise",
      isSelected: false,
      formcontrol: "IsLocationWiseWithIds",
    },
    {
      id: 2,
      value: "Is Section Wise",
      isSelected: false,
      formcontrol: "IsSectionWiseWithIds",
    },
    {
      id: 3,
      value: "Is Unit Wise",
      isSelected: false,
      formcontrol: "IsUnitWiseWithIds",
    },
  ];

  isAllSelected(item, event: any) {
    this.filterPanelOptions.forEach((val) => {
      if (val.id == item.id) {
        val.isSelected = !val.isSelected;
        if (event.target.checked) {
          this.selectedFilterOption = item.value;
          // this.incidentListForm.patchValue({
          //   [item.formcontrol]:true
          // })
          this.incidentListForm.controls[item.formcontrol].setValue(true);
          this.incidentListForm.controls[
            item.formcontrol
          ].updateValueAndValidity();
        } else {
          this.incidentListForm.controls[item.formcontrol].setValue(false);
          this.incidentListForm.controls[
            item.formcontrol
          ].updateValueAndValidity();
        }
      } else {
        val.isSelected = false;
        this.incidentListForm.controls[val.formcontrol].setValue(false);
        this.incidentListForm.controls[
          val.formcontrol
        ].updateValueAndValidity();
      }
    });
  }

  isAllSelectedtwo(item, event: any) {
    this.filterPanelOptions2.forEach((val) => {
      if (val.id == item.id) {
        val.isSelected = !val.isSelected;
        if (event.target.checked) {
          this.selectedFilterOption = item.value;
          // this.incidentListForm.patchValue({
          //   [item.formcontrol]:true
          // })
          this.incidentListForm.controls[item.formcontrol].setValue(true);
          this.incidentListForm.controls[
            item.formcontrol
          ].updateValueAndValidity();
        } else {
          this.incidentListForm.controls[item.formcontrol].setValue(false);
          this.incidentListForm.controls[
            item.formcontrol
          ].updateValueAndValidity();
        }
      } else {
        val.isSelected = false;
        this.incidentListForm.controls[val.formcontrol].setValue(false);
        this.incidentListForm.controls[
          val.formcontrol
        ].updateValueAndValidity();
      }
    });
  }

  callMaster() {
    if (this.isfilterShown) {
    console.log(this.isfilterShown);

      if (this.isfilterShown.ShiftIds) {
        this.shiftList.getShifts().subscribe((data) => {
          this.shift = _clone(data.Result);
        });
      }
      if (
        this.isfilterShown.IncidentCategoryId ||
        this.isfilterShown.CategoryIds
      ) {
        this._incidentData.getCategory().subscribe((data) => {
          this.IncidentCatagoryList = _clone(data.Result);
        });
      }
      if (this.isfilterShown.IncidentTypeIds) {
        this._incidentTypeService.getIMIncidentType().subscribe((data) => {
          this.incidentTypeList = _clone(data.Result);
        });
      }
      if (
        this.isfilterShown.GICId ||
        this.isfilterShown.GIC ||
        this.isfilterShown.ReportedByGIC ||
        this.isfilterShown.ReportedInGIC || 
        this.isfilterShown.FacilityMasterId_OBSERVER
      ) {
        this._imreportService.getGICList().subscribe((data) => {
          this.GICList = _clone(data.Result);
        });
      }
      if (
        this.isfilterShown.Area ||
        this.isfilterShown.AreaId ||
        this.isfilterShown.AreaIds
      ) {
        this._imreportService.getAreaList().subscribe((data) => {
          this.AreaList = _clone(data.Result);
        });
      }
      if (this.isfilterShown.Location) {
        this._imreportService.getLocationList().subscribe((data) => {
          this.LocationList = _clone(data.Result);
        });
      }
      if (this.isfilterShown.IncidentNatureIds) {
        this._imreportService.getIncidentNature().subscribe((data) => {
          this.IncidentNatureList = _clone(data.Result);
        });
      }
      if (this.isfilterShown.ComulativeFilters) {
        this._imreportService.getClusterList().subscribe((data) => {
          this.ClusterList = _clone(data.Result);
        });
      }
      if (
        this.isfilterShown.DepartmentIds ||
        this.selectedFilterOption == "Is Department Wise" ||
        this.selectedFilterOption == "Is User Wise"
      ) {
        this._imreportService.getDepartmentList().subscribe((data) => {
          this.DepartmentList = _clone(data.Result);
        });
      }
      if (
        this.isfilterShown.PositionIds ||
        this.selectedFilterOption == "Is Position Wise"
      ) {
        this._imreportService.getPositionList().subscribe((data) => {
          this.PositionList = _clone(data.Result);
        });
      }
      if (this.isfilterShown.OperationalCauseIds) {
        this._imreportService.getCauseFactorType().subscribe((data) => {
          this.OperationalCauseList = _clone(data.Result);
        });
      }
      if (this.isfilterShown.IncidentStatusId) {
        this._incidentData.getIncidentStatus().subscribe((data) => {
          this.incidentStatusList = data.Result;
        });
      }
      if (this.isfilterShown.IncidentClassificationId) {
        this._incidentClassification
          .getIMIncidentClassificationType()
          .subscribe((data) => {
            this.IncidentClassificationList = data.Result;
          });
      }
      if (this.isfilterShown.PSMType) {
        this.PSMList = [
          { PSMTypeName: "Manage For Continuous Improvement - C", PSMType: 3 },
          { PSMTypeName: "Incorporate Risk Reduction Measure - B", PSMType: 2 },
          { PSMTypeName: "Intoreable - A", PSMType: 1 },
        ];
      }
      if (this.isfilterShown.LineIds) {
        this.lineList = [
          {
            Name: "Line I",
            LineId: 1,
          },
          {
            Name: "Line II",
            LineId: 1,
          },
          {
            Name: "Line III",
            LineId: 1,
          },
          {
            Name: "Line IV",
            LineId: 1,
          },
          {
            Name: "Line V",
            LineId: 1,
          },
          {
            Name: "Line VI",
            LineId: 1,
          },
        ];
      }
      if (this.isfilterShown.LineIds) {
        // this._imreportService.getLine().subscribe((data) => {
        //   this.lineList = _clone(data.Result);
        // });
      }
      if (this.isfilterShown.AgeGroup) {
        this.ageGroupList = [
          { Title: "All", Id: 1 },
          { Title: "UpTo 30", Id: 2 },
          { Title: "Between 30 and 50", Id: 3 },
          { Title: "Above 50", Id: 4 },
        ];
      }
      if (this.isfilterShown.EmployeeTypeIds) {
        this.emptypelist = [
          { EmployeeTypeId: 1, EmployeeTypeName: " Own Employee" },
          { EmployeeTypeId: 1, EmployeeTypeName: " Contractor Employee" },
        ];
      }
      if (
        this.isfilterShown.FunctionIds ||
        this.selectedFilterOption == "Is Function Wise"
      ) {
        // this._imreportService.getFunctionList().subscribe((data) => {
        //   this.functionlist = _clone(data.Result);
        // });
      }
    }
  }

  // On resize window screen
  onResize(event) {
    this.innerHeight = event.target.innerHeight - 115;
  }

  // getPackageFeature() {
  //   let Code = "EXPORT_PDF,EXPORT_EXCEL";
  //   let ModuleId = Module.BBS;
  //   this._package.getPackageFeature(Code, ModuleId).subscribe(
  //     (data) => {
  //       this.loading = false;
  //       if (data.MessageType == MessageType.Success) {
  //         this.isPDFAvailable =
  //           data.Result.filter(
  //             (x) => x.Code == "EXPORT_PDF" && x.Value == "true"
  //           ).length > 0;
  //         this.isExcelAvailable =
  //           data.Result.filter(
  //             (x) => x.Code == "EXPORT_EXCEL" && x.Value == "true"
  //           ).length > 0;
  //       }
  //     },
  //     (err) => {
  //       this.loading = false;
  //       Notify.ApiError(err, this.translate);
  //     },
  //     () => {
  //       this.loading = false;
  //     }
  //   );
  // }

  setCurrentMonthYear(): void {
    const currentDate = new Date();
    const month = currentDate.toLocaleString("default", { month: "short" });
    const year = currentDate.getFullYear().toString();
    this.incidentListForm.get("monthName").setValue(month);
    this.incidentListForm
      .get("monthNumber")
      .setValue(currentDate.getMonth() + 1);
    this.incidentListForm.get("year").setValue(year);
  }

  downloadReport(paramFormat, file: string, toast) {
    if (this.reportTypeId == 3) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1");
      const mergeCount = Object.keys(this.ListofIncident[0]).length;
      const titleRow = worksheet.addRow([this.Title]);
      titleRow.getCell(1, mergeCount).value = this.Title;
      titleRow.getCell(1, mergeCount).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      titleRow.getCell(1, mergeCount).font = {
        bold: true,
        size: 18,
        color: { argb: "FF0000" },
      };
      worksheet.mergeCells(1, 1, 2, mergeCount);
      const formattedDate = new Date().toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      const dateText = `Date: ${formattedDate}`;
      const dateRow = worksheet.addRow([dateText]);
      const cell = dateRow.getCell(1);
      cell.font = { bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      const textLength = dateText.length;
      const maxWidth = 20;
      const calculatedWidth = Math.min(textLength * 5, maxWidth);
      worksheet.getColumn(1).width = calculatedWidth;
      const headerRow = worksheet.addRow(Object.keys(this.ListofIncident[0]));
      Object.keys(this.ListofIncident[0]).forEach((r, i) => {
        const keyHeaderCell = headerRow.getCell(i + 1);
        const value = r.replace(/([a-z])([A-Z])/g, "$1 $2");
        keyHeaderCell.value = value;
        keyHeaderCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "00468b" },
        };
        keyHeaderCell.font = { color: { argb: "FFFFFF" } };
      });
      worksheet.mergeCells(4, 17, 4, 24);
      let rowCounter = 5;
      this.ListofIncident.forEach((data, mainIndex) => {
        const recommendationArray = data.IncidentDetailsReportRecommandations;
        const mainRow = worksheet.insertRow(rowCounter);
        Object.values(data).forEach((rec, i) => {
          if (
            typeof rec === "string" ||
            typeof rec === "number" ||
            typeof rec === "boolean"
          ) {
            const mainCell = mainRow.getCell(i + 1);
            mainCell.value = rec;
          }
        });
        // const mainDataRow = worksheet.addRow(rowData)
        // rowIndex = mainDataRow.number + 1;
        rowCounter++;
        if (recommendationArray && recommendationArray.length > 0) {
          let headerTotalColumn = Object.keys(data).length;
          const headerRow = worksheet.insertRow(rowCounter);
          Object.keys(recommendationArray[0]).forEach((key, i) => {
            const keyHeaderCell = headerRow.getCell(headerTotalColumn);
            const value = key.replace(/([a-z])([A-Z])/g, "$1 $2");
            keyHeaderCell.value = value;
            keyHeaderCell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "6464ff" },
            };
            keyHeaderCell.font = { color: { argb: "FFFFFF" } };
            headerTotalColumn += 1;
          });
          rowCounter++;
          recommendationArray.forEach((recommendation, arrayIndex) => {
            // let mainRow =
            let mainTotalColumn = Object.keys(data).length;
            const newRow = worksheet.insertRow(rowCounter);
            Object.values(recommendation).forEach((rec, i) => {
              const keyValue = newRow.getCell(mainTotalColumn);
              keyValue.value = rec;
              mainTotalColumn += 1;
            });
            rowCounter++;
          });
        }
      });
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.Title}.xlsx`;
        anchor.click();
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 100);
      });
    }

    console.log(this.reportTypeId);
    if (this.reportTypeId == 2) {
      // Create a new Excel workbook and worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1");
      // Get the table elements and h2 tag data from the DOM
      const tables = document.querySelectorAll("#excel-table");
      let h1Index = 0;
      let rowIndex = 1;
      // Add the custom text row
      const customText = this.Title;
      const textRow = worksheet.addRow([customText]);
      textRow.getCell(1).font = {
        size: 16,
        bold: true,
        color: { argb: "FF0000" },
      };
      textRow.getCell(1).alignment = { horizontal: "center" };
      textRow.getCell(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF" },
      };
      textRow.height = 40;
      worksheet.mergeCells(`A${rowIndex}:D${rowIndex}`); // Merge the cells for the custom text row
      rowIndex++;
      // Add the date at the specified position and format
      const dateCell = worksheet.getCell(`A${rowIndex}`);
      const today = new Date();
      const formattedDate = `Date - ${today.getDate()}/${
        today.getMonth() + 1
      }/${today.getFullYear()}`;
      dateCell.value = formattedDate;
      dateCell.alignment = { horizontal: "right" };
      dateCell.font = { bold: true };

      // Merge the cells for the date row
      worksheet.mergeCells(`A${rowIndex}:D${rowIndex}`);

      // Set the width of the date column to 60
      worksheet.getColumn("A").width = 60;

      rowIndex += 2; // Increment the row index for h1data and table

      for (let i = 0; i < Math.min(tables.length); i++) {
        const h1datas = document.getElementById("heading-" + i);
        const table = tables[i];

        // Add extra row spaces after each table
        if (i > 0) {
          for (let j = 0; j < 3; j++) {
            worksheet.addRow([]);
            rowIndex++;
          }
        }

        // const imageUrl = 'https://source.unsplash.com/collection/190727/30x30'; // Replace with your image URL
        // const imageRow = worksheet.addRow([]);
        // const imageColStart = 1;
        // imageRow.height = 40;

        // // Add the image using a hyperlink
        // const imageCell = imageRow.getCell(imageColStart);
        // imageCell.value = {
        // hyperlink: imageUrl,
        // // text: '',
        // };
        // imageCell.alignment = { vertical: 'middle', horizontal: 'center' };
        // imageCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };
        // imageCell.border = { bottom: { style: 'thin' } };

        // // Merge the cells for the image row
        // worksheet.mergeCells(`A${rowIndex}:D${rowIndex}`);

        // rowIndex++; // Increment the row index
        // // Add h1data to the worksheet
        const h1data = h1datas.innerText;
        const h1Row = worksheet.addRow([]);
        h1Row.getCell(2).value = `UnitName: ${h1data}`;
        h1Row.getCell(2).font = { bold: true };
        h1Row.getCell(2).alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.addRow([]);

        const tableWidth = table.clientWidth;
        worksheet.getColumn(1).width = tableWidth / 7;
        rowIndex++; // Increment the row index

        // Get table rows
        const rows = Array.from(table.getElementsByTagName("tr"));

        // Extract the table headers
        const headerRow = rows.shift();
        const headerData = Array.from(headerRow.getElementsByTagName("th")).map(
          (th) => th.innerText
        );

        const headerRowInExcel = worksheet.addRow(headerData);
        headerRowInExcel.font = { color: { argb: "FFFFFF" }, bold: true };
        headerRowInExcel.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        headerRowInExcel.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "443a80" },
          };
          cell.font = { color: { argb: "FFFFFF" } };
        });

        // Loop through the remaining rows and add them to the worksheet
        rows.forEach((row) => {
          const rowData = Array.from(row.getElementsByTagName("td")).map(
            (td) => td.innerText
          );
          worksheet.addRow(rowData);
          console.log(row);
          h1Index++;
        });

        // Auto-fit the column widths
        worksheet.columns.forEach((column, index) => {
          if (index === 0) {
            column.width = 15;
          } else {
            column.width = 40;
          }
        });

        rowIndex += rows.length + 1;
      }

      // Generate a binary buffer for the Excel file
      workbook.xlsx.writeBuffer().then((buffer) => {
        // Create a Blob from the buffer
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a temporary anchor element
        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(blob);
        anchor.download = `${this.Title}.xlsx`;

        // Programmatically click the anchor element to trigger the download
        anchor.click();

        // Clean up the URL.createObjectURL reference
        setTimeout(() => {
          URL.revokeObjectURL(anchor.href);
        }, 100);
      });
    } else if (
      !this.reportTypeId ||
      this.reportTypeId == 1 ||
      this.reportTypeId == 4
    ) {
      this.incidentListForm.controls.export.setValue(true);
      this.alertToast();
      if (paramFormat == "Excel") {
        this.format = "Excel";
        let type = "";
        if (this.reportTypeId == 4) {
          type = "xlsx";
        } else if (this.total < _$config.excelLimit) {
          type = "xlsx";
        } else {
          type = "csv";
        }

        this.incidentListForm.controls.format.setValue(type);
        this.incidentListForm.controls.ExportType.setValue(type);
        this._imreportService.downloadReports(
          type,
          this.incidentListForm.value,
          this.currentUrl,
          file
        );
      } else if (paramFormat == "PDF") {
        this.format = "PDF";
        this.incidentListForm.controls.format.setValue("pdf");
        this.incidentListForm.controls.ExportType.setValue("pdf");
        this._imreportService.downloadReports(
          "pdf",
          this.incidentListForm.value,
          this.currentUrl,
          file
        );
      }
    }
  }

  //for download
  alertToast(timerProgressBar: boolean = false, close?: any) {
    this.toastvisible = true;
    setTimeout(() => {
      this.toastvisible = false;
    }, 3000);
  }

  initList() {
    // this.getEmployee();
    this.page.Total = 0;
    this.page.CurrentPage = 1;
    this.page.PageSize = _$config.pageSize;
  }
  // Set Paging
  setPage(pageInfo) {
    this.page.CurrentPage = pageInfo.page;
    if (this.serverSidePagination == "Yes") {
      this.searchlistOfIncident(false);
    } else {
      this.loading = true;
      this.getClientData();
    }
  }

  
  getBack(){
    const params = this.filterService.popFilters();
    // console.log(this.checkUrl)
   
           if(!!params&& params != undefined){
            this.params = params;
            if (this.params) {
          if (!!this.params.FromDate && !!this.params.ToDate) {
            this.incidentListForm.controls.selected.patchValue({
              startDate: moment(this.params.FromDate),
              endDate: moment(this.params.ToDate),
            });
            this.incidentListForm.controls.selected.updateValueAndValidity();
          }
        }
        this.incidentListForm.patchValue(this.params);
        // this.incidentListForm.controls.selected.value.startDate = new Date(this.params.FromDate)
        // this.incidentListForm.controls.selected.value.endDate = new Date(this.params.ToDate)
        this.incidentListForm.updateValueAndValidity();
       
    }
 
     
  }

  // setPage(pageInfo) {
  //   this.page.CurrentPage = pageInfo.page;
  //   this.searchlistOfIncident(false);
  // }
 
  checkIfUrlExists (url, array) {
      const arrayToCheck = array.slice(0, -1);
      if(arrayToCheck.some(element => element.includes(url))){
        if(array[array.length-1] == url){
            return true
        }
       return false
      }
      return false
  };

  // searchlistOfIncident(isPush) {
  //   const temp = this.filterService.getHistory();
  //   const temp2= temp[temp.length - 1].split('?')
  //   if(this.checkIfUrlExists(this.currentUrl,temp) && (this.currentUrl == temp2[0] && !isPush)){
  //     this.getBack()
  //   }
  //   if (isPush ) {
  //     const filtersToPush: { [key: string]: any } = {};
  //     for (const key in this.incidentListForm.value) {
  //       const value = this.incidentListForm.value[key];
  //       if (Array.isArray(value) && value.length > 0) {
  //         filtersToPush[key] = value;
  //       } else if (typeof value === 'string' && value.trim() !== '') {
  //         filtersToPush[key] = value;
  //       } else if (!Array.isArray(value) && typeof value !== 'string' && value != null) {
  //         filtersToPush[key] = value;
  //       }
  //     }
  //     this.filterService.pushFilters(filtersToPush);
  //   }


  //   this.loading = true;
  //   this._imreportService
  //     .getListOfIncident(
  //       this.page,
  //       this.incidentListForm.value,
  //       this.currentUrl
  //     )
  //     .subscribe(
  //       (data) => {
  //         if (data && data.Result) {
  //           if (data.MessageType == MessageType.Success) {
  //             console.log("REPORT data.Result==", data.Result);
  //             if (
  //               data &&
  //               data.Result &&
  //               data.Result.data &&
  //               data.Result.data.ServerSidePagination
  //             ) {
  //               this.serverSidePagination =
  //                 data.Result.data.ServerSidePagination;
  //             }
              
  //             this.reportTypeId = data.Result.ReportTypeId;
  //             if (!data.Result.ReportTypeId || data.Result.ReportTypeId == 1 || data.Result.ReportTypeId == 4) {
  //               this.Title = _clone(data.Result.data.Title);
  //               this.note = data.Result.data.Note;
  //               console.log(this.Title);
  //               // this.SetReport_Data(data.Result.data.data ? _clone(data.Result.data.data) : []);
  //               this.ListofIncident = data.Result.data.data
  //                 ? _clone(data.Result.data.data)
  //                 : [];
  //               this.isfilterShown = data.Result.Filters
  //                 ? _clone(data.Result.Filters)
  //                 : [];
  //               if (this.isfilterShown) {
  //                 for (var property in this.isfilterShown) {
  //                   if (!this.isfilterShown.hasOwnProperty(property)) {
  //                     continue;
  //                   }
  //                   if (!!property) {
  //                     this.filterNames.push(property);
  //                   }
  //                 }
  //               }
  //             }
  //             if (data.Result.ReportTypeId == 2) {
  //               this.Title = _clone(data.Result.Title);
  //               this.note = data.Result.Note;
  //               console.log(this.Title);
  //               // this.SetReport_Data(data.Result.data.data ? _clone(data.Result.data.data) : []);
  //               this.ListofIncident = data.Result.data
  //                 ? _clone(data.Result.data)
  //                 : [];
  //               this.isfilterShown = data.Result.Filters
  //                 ? _clone(data.Result.Filters)
  //                 : [];
  //               if (this.isfilterShown) {
  //                 for (var property in this.isfilterShown) {
  //                   if (!this.isfilterShown.hasOwnProperty(property)) {
  //                     continue;
  //                   }
  //                   if (!!property) {
  //                     this.filterNames.push(property);
  //                   }
  //                 }
  //               }
  //             }
  //             if (!data.Result.ReportTypeId || data.Result.ReportTypeId == 1 || data.Result.ReportTypeId == 4) {
  //               this.clicks = data.Result.Click
  //                 ? _clone(data.Result.Click)
  //                 : {};
  //               this.availablecolumn = data.Result.Colummn
  //                 ? _clone(data.Result.Colummn)
  //                 : [];
  //               //this.availablecolumn["Sr_No"] = true;
  //               this.availablecolumn = Object.assign(
  //                 { SN: true },
  //                 this.availablecolumn
  //               );
  //               this.filteredkeys = Object.keys(this.availablecolumn).filter(
  //                 (k) => this.availablecolumn[k]
  //               );
  //               let myObj = data.Result.Colummn
  //                 ? _clone(data.Result.Colummn)
  //                 : [];
  //               myObj = Object.assign({ SN: true }, this.availablecolumn);
  //               for (let key in myObj) {
  //                 if (
  //                   myObj.hasOwnProperty(key) &&
  //                   (key.includes("Click") ||
  //                     key.includes("Link") ||
  //                     key.includes("Download"))
  //                 ) {
  //                   delete myObj[key];
  //                 }
  //               }
  //               this.availablecolumnKeys = Object.keys(myObj).map(
  //                 (val, index) =>
  //                   val === "SN"
  //                     ? { value: val, disabled: true }
  //                     : { value: val }
  //               );
  //               console.log(this.availablecolumn);
  //               // this.availablecolumnKeys = Object.keys(this.availablecolumn);
  //               // this.setcolumns(this.availablecolumn, this.availablecolumnKeys);

  //               this.finalrowData = data.Result.data.data
  //                 ? _clone(data.Result.data.data)
  //                 : [];
  //               for (let z = 0; z < this.finalrowData.length; z++) {
  //                 this.finalrowData[z]["SN"] = z + 1;
  //               }
  //               this.totalArr = data.Result.data.TotalData;
  //               if (data.Result.data) {
  //                 if (data.Result.data.TotalData) {
  //                   this.totalData = [];
  //                   let keys: any = Object.keys(data.Result.data.TotalData);
  //                   for (let i = 0; i < keys.length; i++) {
  //                     let column = keys[i];
  //                     if (
  //                       isNumber(data.Result.data.TotalData[column]) &&
  //                       this.availablecolumn[column]
  //                     ) {
  //                       var result = column.replace(/([A-Z])/g, " $1");
  //                       var name =
  //                         result.charAt(0).toUpperCase() + result.slice(1);
  //                       this.totalData.push({
  //                         value: data.Result.data.TotalData[column],
  //                         name: name,
  //                       });
  //                     }
  //                   }
  //                 }
  //               }
  //               console.log(this.totalData);
  //               this.SetReport_Data(this.finalrowData);
  //               this.testsetcolumns(this.filteredkeys);
  //               // this.total = data.Total + 1;
  //               this.total = data.Total;
  //             }
  //             this.loading = false;
  //             this.closeFilter();
  //           }
  //         } else {
  //           this.loading = false;
  //         }
  //       },
  //       (err) => {
  //         this.loading = false;
  //         Notify.ApiError(err, this.translate);
  //       },
  //       () => {
  //         this.loading = false;
  //       }
  //     );
  //   this.setFilters(this.incidentListForm.value);
  // }

  searchlistOfIncident(isPush) {


    this.loading = true;
    const temp = this.filterService.getHistory();
    const temp2= temp[temp.length - 1].split('?')
    if(this.checkIfUrlExists(this.currentUrl,temp) && (this.currentUrl == temp2[0] && !isPush)){
      this.getBack()
    }
    if (isPush ) {
      const filtersToPush: { [key: string]: any } = {};
      for (const key in this.incidentListForm.value) {
        const value = this.incidentListForm.value[key];
        if (Array.isArray(value) && value.length > 0) {
          filtersToPush[key] = value;
        } else if (typeof value === 'string' && value.trim() !== '') {
          filtersToPush[key] = value;
        } else if (!Array.isArray(value) && typeof value !== 'string' && value != null) {
          filtersToPush[key] = value;
        }
      }
      this.filterService.pushFilters(filtersToPush);
    }
     
    // if (this.employeeId) {
    //   this.incidentListForm.controls.EmployeeIds.setValue(this.employeeId)
    //   this.incidentListForm.controls.UnitIds.setValue(this.unitIds)
    //   const currentDate = new Date();
    //   const startDate = new Date(2013, 7, 5, 12, 59, 54);
    //   const endDate = currentDate;
    //   const myDate = new Date("Mon Aug 05 2013 12:59:54 GMT+0530 (India Standard Time)");
    //   this.incidentListForm.controls.frommonth.setValue(myDate)
    //   this.incidentListForm.controls.selected.setValue({ startDate, endDate })
    //   this.currentUrl = this.url
            
    // }
    const selected = this.incidentListForm.controls.selected.value;
    const dateRange = {
      startDate: selected.startDate,
      endDate: selected.endDate
    };
    
    localStorage.setItem("dateRange", JSON.stringify(dateRange));
    console.log(this.incidentListForm.value);
    this._imreportService
      .getListOfIncident(
        this.page,
        this.incidentListForm.value,
        this.currentUrl
      )
      .subscribe(
        (data) => {
          if (data && data.Result) {
            if (data.MessageType == MessageType.Success) {
              if(data.Result.isExcelAvailable && data.Result.isPDFAvailable ){
                this.isExcelAvailable = data.Result.isExcelAvailable
                this.isPDFAvailable = data.Result.isPDFAvailable
              }
              else{
                this.isExcelAvailable = true
                this.isPDFAvailable = false
              }
          
              this.reportTypeId = data.Result.ReportTypeId;
              if (!data.Result.ReportTypeId || data.Result.ReportTypeId == 1) {
                this.Title = _clone(data.Result.data.Title);
                this.note = data.Result.data.Note;
                 
                // this.SetReport_Data(data.Result.data.data ? _clone(data.Result.data.data) : []);
                this.ListofIncident = data.Result.data.data
                  ? _clone(data.Result.data.data)
                  : [];
                this.isfilterShown = data.Result.Filters
                  ? _clone(data.Result.Filters)
                  : [];
                if (this.isfilterShown) {
                  for (var property in this.isfilterShown) {
                    if (!this.isfilterShown.hasOwnProperty(property)) {
                      continue;
                    }
                    if (!!property) {
                      this.filterNames.push(property);
                    }
                  }
                }
              }
              if (data.Result.ReportTypeId == 2) {
                this.Title = _clone(data.Result.Title);
                this.note = data.Result.Note;
                 
                // this.SetReport_Data(data.Result.data.data ? _clone(data.Result.data.data) : []);
                this.ListofIncident = data.Result.data
                  ? _clone(data.Result.data)
                  : [];
                this.isfilterShown = data.Result.Filters
                  ? _clone(data.Result.Filters)
                  : [];
                if (this.isfilterShown) {
                  for (var property in this.isfilterShown) {
                    if (!this.isfilterShown.hasOwnProperty(property)) {
                      continue;
                    }
                    if (!!property) {
                      this.filterNames.push(property);
                    }
                  }
                }
              }
              if (!data.Result.ReportTypeId || data.Result.ReportTypeId == 1) {
                this.clicks = data.Result.Click ? _clone(data.Result.Click) : {};
                this.availablecolumn = data.Result.Colummn
                  ? _clone(data.Result.Colummn)
                  : [];
                //this.availablecolumn["Sr_No"] = true;
                this.availablecolumn = Object.assign(
                  { Sr_No: true },
                  this.availablecolumn
                );
                this.filteredkeys = Object.keys(this.availablecolumn).filter(
                  (k) => this.availablecolumn[k]
                );
                let myObj = data.Result.Colummn ? _clone(data.Result.Colummn) : [];
                myObj = Object.assign({ Sr_No: true }, this.availablecolumn);
                for (let key in myObj) {
                  if (
                    myObj.hasOwnProperty(key) &&
                    (key.includes("Click") ||
                      key.includes("Link") ||
                      key.includes("Download"))
                  ) {
                    delete myObj[key];
                  }
                }
                this.availablecolumnKeys = Object.keys(myObj).map((val, index) =>
                  val === "Sr_No" ? { value: val, disabled: true } : { value: val }
                );
                 
                // this.availablecolumnKeys = Object.keys(this.availablecolumn);
                // this.setcolumns(this.availablecolumn, this.availablecolumnKeys);

                this.finalrowData = data.Result.data.data
                  ? _clone(data.Result.data.data)
                  : [];
                for (let z = 0; z < this.finalrowData.length; z++) {
                  this.finalrowData[z]["Sr_No"] = z + 1;
                }
                this.totalArr = data.Result.data.TotalData;
                if (data.Result.data) {
                  if (data.Result.data.TotalData) {
                    this.totalData = [];
                    let keys: any = Object.keys(data.Result.data.TotalData);
                    for (let i = 0; i < keys.length; i++) {
                      let column = keys[i];
                      if (
                        isNumber(data.Result.data.TotalData[column]) &&
                        this.availablecolumn[column]
                      ) {
                        var result = column.replace(/([A-Z])/g, " $1");
                        var name = result.charAt(0).toUpperCase() + result.slice(1);
                        this.totalData.push({
                          value: data.Result.data.TotalData[column],
                          name: name,
                        });
                      }
                    }
                  }
                }
                 
                this.SetReport_Data(this.finalrowData);
                this.testsetcolumns(this.filteredkeys);
                // this.total = data.Total + 1;
                this.total = data.Total;
              }
              this.loading = false;
              this.closeFilter();
            }
          } else {
            this.loading = false;
          }
        },
        (err) => {
          this.loading = false;
          Notify.ApiError(err, this.translate);
        },
        () => {
          this.loading = false;
        }
      );
    this.setFilters(this.incidentListForm.value);
     
  }

  getColumns(data) {
    const columnNames = [];
    if (data) {
      Object.keys(data).forEach((column) => {
        if (data[column] === true) {
          columnNames.push(column);
        }
      });
    }
    return columnNames;
  }

  containsLink(item, column) {
    if (
      item &&
      item.hasOwnProperty(column + "_Click") &&
      item.hasOwnProperty(column + "_Link")
    ) {
      return true;
    }
    return false;
  }

  getTotalDataValue(column, data, dataItem) {
    if (data) {
      const present1 = Object.entries(data).find(
        (k) => k[0].toLowerCase() === column.toLowerCase()
      );
      if (present1) {
        return present1[1];
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  }

  addSpaceBeforeCapital(str: string): string {
    return str.replace(/([A-Z])/g, " $1");
  }

  isNumber(value: any): boolean {
    return typeof value === "number" && !isNaN(value);
  }

  downloadSample(columnname, data) {
     
    let column = columnname + "_Link";
    let downloadfileext = columnname + "_Download";
    let url = data[column];
    let filename = data[downloadfileext];
    ApiUtility.downloadStaticFile(url, filename);
  }

  loadingIndicator = true;
  reorderable = true;

//  SetReport_Data (ReportData) {
//     if (ReportData && ReportData.length) {
//       this.rows = ReportData;
//       if (!!this.totalData) {
//         this.rows.push(this.totalData);
//       }
//       if (this.rows.length) {
//         this.column = Object.keys(this.rows[0]);
//       }

//       for (let j = 0; j < this.rows.length; j++) {
//         for (let k = 0; k < this.column.length; k++) {
//           if (
//             typeof this.rows[j][this.column[k]] == "object" &&
//             this.rows[j][this.column[k]] !== null
//           ) {
//             if (this.objAarray.includes(this.column[k]) === false)
//               this.objAarray.push(this.column[k]);
//             //  let html;
//             //  html = this.GFG_FUN(this.rows[j][this.column[k]]);
//             // html = this.myFunction(this.rows[j][this.column[k]]);

//             // this.rows[j][this.column[k]] = html;
//           }
//         }
//       }
//       // for (let index = 0; index < this.column.length; index++) {
//       //   this.columns.push({
//       //     prop: this.column[index],
//       //     '`this.column[index]`': this.column[index],
//       //     flexGrow: 1.0,
//       //     minWidth: 100
//       //   })
//       //   this.allColumns.push({
//       //     prop: this.column[index],
//       //     '`this.column[index]`': this.column[index],
//       //     flexGrow: 1.0,
//       //     minWidth: 100
//       //   })

//       // }
//       this.loadingIndicator = false;
//     }
//   }

SetReport_Data(ReportData) {
  if (ReportData && ReportData.length) {
    this.rows = ReportData;
    if (!!this.totalData) {
      this.rows.push(this.totalData);
    }
    if (this.rows.length) {
      this.column = Object.keys(this.rows[0]);
    }

    for (let j = 0; j < this.rows.length; j++) {
      for (let k = 0; k < this.column.length; k++) {
        if (
          typeof this.rows[j][this.column[k]] == "object" &&
          this.rows[j][this.column[k]] !== null
        ) {
          if (this.objAarray.includes(this.column[k]) === false)
            this.objAarray.push(this.column[k]);
          //  let html;
          //  html = this.GFG_FUN(this.rows[j][this.column[k]]);
          // html = this.myFunction(this.rows[j][this.column[k]]);

          // this.rows[j][this.column[k]] = html;
        }
      }
    }
  
    // for (let index = 0; index < this.column.length; index++) {
    //   this.columns.push({
    //     prop: this.column[index],
    //     '`this.column[index]`': this.column[index],
    //     flexGrow: 1.0,
    //     minWidth: 100
    //   })
    //   this.allColumns.push({
    //     prop: this.column[index],
    //     '`this.column[index]`': this.column[index],
    //     flexGrow: 1.0,
    //     minWidth: 100
    //   })

    // }
    this.loadingIndicator = false;
  }
}

  public getClientData(): void {
    const startIndex = (this.page.CurrentPage - 1) * this.page.PageSize;
    const endIndex = startIndex + this.page.PageSize;
    this.rows = this.ListofIncident.slice(startIndex, endIndex);
    this.total = this.ListofIncident.length;
    this.loading = false;
  }

  GFG_FUN(list) {
    var cols = [];

    for (var i = 0; i < list.length; i++) {
      for (var k in list[i]) {
        if (cols.indexOf(k) === -1) {
          // Push all keys to the array
          cols.push(k);
        }
      }
    }

    // Create a table element
    var table = document.createElement("table");

    // Create table row tr element of a table
    var tr = table.insertRow(-1);
    // to remove any word including id
    cols = cols.filter((x) => {
      if (x.includes("Id")) {
        console.log(x);
      } else {
        return x;
      }
    });
    //
    for (var i = 0; i < cols.length; i++) {
      // Create the table header th element
      var theader = document.createElement("th");
      theader.innerHTML = cols[i];

      // Append columnName to the table row
      tr.appendChild(theader);
    }

    // Adding the data to the table
    for (var i = 0; i < list.length; i++) {
      // Create a new row
      let trow = table.insertRow(-1);
      for (var j = 0; j < cols.length; j++) {
        var cell = trow.insertCell(-1);

        // Inserting the cell at particular place
        cell.innerHTML = list[i][cols[j]];
      }
    }
    // table.setAttribute("border", "2");
    table.setAttribute("class", "table table-bordered");
    // Add the newly created table containing json data
    //this.tableData = table;
    return table.outerHTML;
  }

  // setcolumns(columndata, columnkeys) {
  //   this.columns = [];
  //   for (let x = 0; x < columnkeys.length; x++) {
  //     if (columndata[columnkeys[x]] == true) {
  //       const clickVal = Object.keys(this.clicks).includes(columnkeys[x]) ? this.clicks[columnkeys[x]] : '';
  //       this.columns.push({
  //         prop: columnkeys[x],
  //         '`columnkeys[x]`': columnkeys[x],
  //         flexGrow: 1.0,
  //         minWidth: 100,
  //         cellTemplate: this.viewDetail,
  //         clickValue: clickVal
  //       })
  //     }
  //   }

  // }

  viewIncidentDetail(val) {
    // console.log(val);
    this._imreportService.getincidentDetails(val).subscribe(
      (data: any) => {
        if (data.MessageType == MessageType.Success) {
          this.modelData = data.Result ? _clone(data.Result) : [];
          this.openModal();
        }
      },
      (err) => {
        Notify.ApiError(err, this.translate);
      },
      () => {}
    );
  }

  // toggle(event: Event) {

  //   const isChecked = event.currentTarget['checked'];
  //   const checkedCol = event.currentTarget['id'];
  //   this.availablecolumn[checkedCol] = isChecked;
  //   this.setcolumns(this.availablecolumn, this.availablecolumnKeys);

  // }

  openModal() {
    this.modalRef = this.modalService.show(this.template, {
      backdrop: "static",
      ignoreBackdropClick: false,
      class: "modal-lg",
    });
  }
  openTableModal() {
    this.modalRef = this.modalService.show(this.tableTemplate, {
      backdrop: "static",
      ignoreBackdropClick: false,
      class: "modal-lg",
    });
  }

  createForm(): FormGroup {
    return this.fb.group({
      //incidentOccureFromandToDate: [[moment().startOf('month').toDate(), this.today],],
      // incidentOccureFromandToDate: [{ startdDate: moment(), endDate: moment() }],
      selected: [this.dateRange],
      // date: [this.d],
      month: new Date(),
      monthNumber: [],
      monthName: [],
      year: new Date(),
      ShiftIds: [],
      IncidentCategoryId: [],
      IncidentTypeIds: [null],
      UnitIds: [this.location],
      FacilityMasterId: [],
      FacilityMasterIds: [],
      Details: [""],
      GICId: [],
      GIC: [],
      Area: [],
      Location: [],
      ReportedByGIC: [],
      ReportedInGIC: [],
      ObserverGIC:[],
      CategoryIds: [],
      IncidentNatureIds: [],
      ViewForAllUnits: [],
      OverdueRecommendation: [],
      EnterpriseMasterId: [],
      frommonth: new Date(),
      tomonth: new Date(),
      fromyear: new Date(),
      // year:[],
      // month:[],
      toyear: new Date(),
      PSMType: [],
      OperationalCauseIds: [],
      SubOperationalCauseIds: [],
      AgeGroup: [],
      IncidentDetail: [],
      IsAreaWise: [],
      IsGICWise: [],
      IsUnitWise: [],
      IsClusterWise: [],
      IsareaWiseForCluster: [],
      IsSeverityWise: [],
      IsDepartmentWise: [],
      IsUserWise: [],
      Wise: [],
      LineIds: [],
      ClusterIds: [],
      DepartmentIds: [],
      PositionIds: [],
      ObservationType: [''],
      UnitId: [],
      AreaId: [],
      AreaIds: [],
      ClientRoleId: [],
      EmployeeIds: [],
      ObserverIds: [],
      ResponsibleIds: [],
     
      EmployeeId: [],
      ChecklistId: [],
      severityId: [],
      LineId: [],
      StandardId: [],
      InspectorId: [],
      DepartmentId: [],
      SubSeverityId: [],
      StatusId: [],
      export: [],
      ExportType: [],
      format: [],
      IsCommulativeSearch: [],
      IsCementBussiness: [],
      IsConcreteBussiness: [],
      IsTotalBussiness: [],
      IsWhiteBussiness: [],
      moduleid: [2],
      IsBulkTerminal: [],
      IsGrindingUnits: [],
      IsIntegratedSites: [],
      IsRMC: [],
      FunctionIds: [],
      IsCoalMine: [],
      IsBPD: [],
      IsOverseas: [],
      IsEastZone: [],
      Status: [],
      IsAll:[false],
      IsSafe:[false],
      fromdate:[],
      todate:[],
      IsWhiteCement: [],
      IsUserWiseWithIds: [true],
      IsFunctionWiseWithIds: [false],
      IsPositionWiseWithIds: [false],
      IsDepartmentWiseWithIds: [false],
      IsSectionWiseWithIds: [false],
      IsUnitWiseWithIds: [true],
      IsLocationWiseWithIds: [false],
      IsLWC: [false],
      IsRWC: [false],
      IsMTC: [false],
      IsFullInvestigationNeeded: [false],
      IsInvestigationBeyond7Days: [false],
      IncidentStatusId: [],
      OperationalCauseID: [],
      EmployeeTypeIds: [],
      IncidentClassificationId: [],
      IncidenceCategorizationId: [],
      ShowAll: [true],
    });
  }

  onSelect(data: any) {
    this.masterId = data;
    this.incidentListForm.controls.FacilityMasterIds.setValue(data);
  }

  // onActivate(event) {
  //   if (event.type == 'click') {

  //     if (event.row.IncidentId) {
  //       this._imreportService.getincidentDetails(event.row.IncidentId).subscribe(
  //         (data: any) => {
  //           if (data.MessageType == MessageType.Success) {
  //             this.modelData = data.Result ? _clone(data.Result) : [];
  //             this.openModal();
  //           }
  //         },
  //         err => {

  //           Notify.ApiError(err, this.translate);
  //         },
  //         () => {

  //         });

  //     }
  //   }
  // }

  observationTypeList = [
    { label: 'All', value: 'All' },
    { label: 'Safe', value: 'Safe' },
    { label: 'Unsafe', value: 'Unsafe' }
];

  openfilter() {
    if (!this.dashboardUrl) {
      this.callMaster();
      this.loadedFilterOnce = true;
    }
    this.isApplyFilter = true;
  }
  closeFilter() {
    this.isApplyFilter = false;
    this.setScreenWidth($(window).width());

    // this.incidentListForm = this.createForm();
    // this.incidentListForm.markAsUntouched();
    // this.incidentListForm.markAsPristine();
  }
  setScreenWidth(width) {
    this.entryFormClass = {
      "offsidebar mb-0 wp-lg-3 wp-md-5 wp-sm-6 wp-xs-8": width > 768,
      "card card-default": this.isApplyFilter,
    };
  }

  dateeve(dae) {
    // console.log(dae);
    // console.log(this.fromandtodate);
    this.incidentListForm.value.incidentOccureFromandToDate = dae;
  }
  datepicker(event) {
    this.incidentListForm.value.date = event;
  }
  openDatepicker() {
    this.pickerDirective.open();
  }
  clearCol(item) {
    if (item !== "Sr_No") {
      this.filteredkeys = this.filteredkeys.filter((val) => val !== item);
      this.testsetcolumns(this.filteredkeys);
    }
  }

  onOpenYearCalendar(container) {
    container.yearSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode("year");
  }

  // testsetcolumns(columnkeys) {
  //   this.columns = [];
  //   for (let index = 0; index < this.objAarray.length; index++) {
  //     const element = this.objAarray[index];
  //     Object.assign(this.checkclick, { [element]: "fa fa-bars" });
  //   }
  //   // const tableClicks = { PendingRecommendation: "fa fa-bars" }
  //   for (let x = 0; x < columnkeys.length; x++) {
  //     const clickVal = Object.keys(this.clicks).includes(columnkeys[x])
  //       ? this.clicks[columnkeys[x]]
  //       : "";
  //     const tableClickVal = Object.keys(this.checkclick).includes(columnkeys[x])
  //       ? this.checkclick[columnkeys[x]]
  //       : "";
  //     let click: boolean;
  //     let key;
  //     let currentcolumnname: any = columnkeys[x] + "_Click";
  //     key = Object.keys(this.availablecolumn).filter(
  //       (x) => x == currentcolumnname
  //     );
  //     if (key.length) {
  //       click = true;
  //     } else {
  //       click = false;
  //     }

  //     this.columns.push({
  //       prop: columnkeys[x],
  //       "`columnkeys[x]`": columnkeys[x],
  //       // flexGrow: 1.0,
  //       // minWidth: 100,
  //       cellTemplate: this.viewDetail,
  //       clickValue: clickVal,
  //       tableClickVal: tableClickVal,
  //       clickable: click,
  //       Totalrow: this.showTotalRow,
  //       sortable: true,
  //     });
  //     // }
  //     // else {
  //     //   this.columns.push({
  //     //     prop: columnkeys[x],
  //     //     '`columnkeys[x]`': columnkeys[x],
  //     //     // flexGrow: 1.0,
  //     //     // minWidth: 100,
  //     //     cellTemplate: this.htmlViewDetail
  //     //   })
  //     // }
  //   }
  //   // this.columns.forEach((col: any) => {
  //   //   const colWidth = this.columnWidths.find(colWidth => colWidth.column === col.prop);
  //   //   if (colWidth) { col.width = colWidth.width; }
  //   // })
  // }

  testsetcolumns(columnkeys) {
    this.columns = [];
    for (let index = 0; index < this.objAarray.length; index++) {
      const element = this.objAarray[index];
      Object.assign(this.checkclick, { [element]: "fa fa-bars" });
    }
    // const tableClicks = { PendingRecommendation: "fa fa-bars" }
    for (let x = 0; x < columnkeys.length; x++) {
      const clickVal = Object.keys(this.clicks).includes(columnkeys[x])
        ? this.clicks[columnkeys[x]]
        : "";

      const tableClickVal = Object.keys(this.checkclick).includes(columnkeys[x])
        ? this.checkclick[columnkeys[x]]
        : "";

      let click: boolean;
      let key;

      let currentcolumnname: any = columnkeys[x] + "_Click";
      key = Object.keys(this.availablecolumn).filter(
        (x) => x == currentcolumnname
      );

      let download: boolean;
      let keyobj;
      let iconLink: boolean;

      let downloadcolumnname: any = columnkeys[x] + "_Download";

      let iconLinkName: any = columnkeys[x] + "_IconLink";
      console.log(this.availablecolumn)
      let iconLinkobj = Object.keys(this.availablecolumn).filter(
        (x) => x == iconLinkName
      );

      if (iconLinkobj && iconLinkobj.length) {
        iconLink = true;
      }

      keyobj = Object.keys(this.availablecolumn).filter(
        (x) => x == downloadcolumnname
      );

      if (key.length && keyobj.length) {
        click = true;
        download = true;
      } else if (key.length) {
        click = true;
        download = false;
      } else if (keyobj.length) {
        click = false;
        download = true;
      } else {
        click = false;
        download = false;
      }
      this.columns.push({
        prop: columnkeys[x],
        "`columnkeys[x]`": columnkeys[x],
        // flexGrow: 1.0,
        // minWidth: 100,
        cellTemplate: this.viewDetail,
        clickValue: clickVal,
        tableClickVal: tableClickVal,
        clickable: click,
        downloadable: download,
        Totalrow: this.showTotalRow,
        sortable: true,
        iconLink: iconLink,
      });

      console.log(this.columns)
       
      // }
      // else {
      //   this.columns.push({
      //     prop: columnkeys[x],
      //     '`columnkeys[x]`': columnkeys[x],
      //     // flexGrow: 1.0,
      //     // minWidth: 100,
      //     cellTemplate: this.htmlViewDetail
      //   })
      // }
    }
    // this.columns.forEach((col: any) => {
    //   const colWidth = this.columnWidths.find(colWidth => colWidth.column === col.prop);
    //   if (colWidth) { col.width = colWidth.width; }
    // })
  }
  

  toggle(event) {
    this.testsetcolumns(this.filteredkeys);
    console.log("toggle called");
  }

  setFilters(value) {
    console.log(value);
  }

  myFunction(list) {
    var innerHtmlClass = document.getElementsByClassName("innerHtmlClass");
    // list.forEach(element => {
    //   var button = document.createElement("button");
    //   button.innerHTML = '<i class="fa fa-bars" > </i>'
    //   button.addEventListener("click", function () {
    //     console.log(element);
    //     alert(element);
    //   })
    //   document.body.appendChild(button);
    //   return button.outerHTML
    // });
    var x = document.createElement("button");
    x.innerHTML = '<i class="fa fa-bars" > </i>';
    x.value = this.GFG_FUN(list);
    // x.addEventListener('click', function () {
    //   alert("hi check details");
    // })
    x.type = "button";
    // document.body.appendChild(x);

    return x.outerHTML;
  }

  viewTableDetail(data) {
    if (data.length) {
      this.tableData = this.GFG_FUN(data);
    } else {
      this.tableData = "No Data Found";
    }
    this.openTableModal();
  }

  // columnWidths = [{ column: "Sr_No", width: 5 }]

  onOpenMonthYearCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode("month");
  }

  onDateSelected(date: Date): void {
    const monthNumber = date.getMonth() + 1;
    this.incidentListForm.get("monthNumber").setValue(monthNumber);
  }

  onOpenCalendar(container) {
    container.yearSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode("year");
  }

  //navigate to link
  // NavigateToPage(name, row) {
  //   let key = name + "_Link";
  //   key = row[key];
  //   let value = key.split("?");
  //   let dataObject = {} as any;
  //   const routeParamsArray = value[1].split("&");
  //   routeParamsArray.forEach((p) => {
  //     dataObject[p.split("=")[0]] = p.split("=")[1];
  //   });
  //   this.currentUrl = value[0];
  //   let param = Encryption.SetQueryParam(dataObject);
  //   this.router.navigate([value[0]], { queryParams: { q: param } });
  //   // this.searchlistOfIncident();
  // }

  NavigateToPage(name, row) {
    let key = name + "_Link";
    key = row[key];
    let value = key.split("?");
    if (value[1]) {
      let dataObject = {} as any;
      const routeParamsArray = value[1].split("&");
      routeParamsArray.forEach((p) => {
        dataObject[p.split("=")[0]] = p.split("=")[1];
      });
      this.currentUrl = value[0];
      let param = Encryption.SetQueryParam(dataObject);
      // this.router.navigated = true;
      if (this.currentUrl == this.previousUrl) {
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() =>
            this.router.navigate([value[0]], { queryParams: { q: param } })
          );
      } else {
        this.router.navigate([value[0]], { queryParams: { q: param } });
      }
    } else {
      let routeString = key;
      let routeParams = routeString.split("|");
      let routePath = routeParams[0];
      routeParams.shift();
      let params = {};
      routeParams.forEach((param) => {
        let keyValue = param.split("=");
        let key = keyValue[0];
        let value = keyValue[1];
        params[key] = value;
      });
      if (params) {
        this.router.navigate([routePath, params]);
      } else {
        this.router.navigate([routePath]);
      }
    }
  }

  setOption(data, frm?: any, cummulativeType?: any) {
    let radioButton: any = document.getElementsByTagName("input");
    for (let i = 0; i < radioButton.length; i++) {
      const element = radioButton[i];
      if (element.type == "radio" && element.id == data.target.id) {
        element.checked = true;
        if (!!frm && frm == "isCummulative") {
          if (!!cummulativeType) {
            this.showSubCummulativeSearch = [];
            this.showSubCummulativeSearch[cummulativeType] = true;
          }
        }
        this.incidentListForm.controls[data.target.id].setValue(true);
        this.incidentListForm.controls[data.target.id].updateValueAndValidity();
      } else if (element.type == "radio" && element.id != data.target.id) {
        if (this.filterNames.includes(element.id) && !frm) {
          this.incidentListForm.controls[element.id].setValue(false);
          this.incidentListForm.controls[element.id].updateValueAndValidity();
        } else if (
          this.cummulativeFilterNames.includes(element.id) &&
          !!frm &&
          frm == "isCummulative"
        ) {
          this.incidentListForm.controls[element.id].setValue(false);
          this.incidentListForm.controls[element.id].updateValueAndValidity();
        }
      }
    }
    if (this.filterNames.includes(data.target.id)) {
      this.searchlistOfIncident(false);
    }
  }

  ageGroupSelect(data) {
    let start, end;
    let agelist: any = [];
    if (data) {
      if (data.Id == 2) {
        start = 0;
        end = 30;
      } else if (data.Id == 3) {
        start = 30;
        end = 50;
      } else if (data.Id == 4) {
        start = 50;
        end = 100;
      } else {
        start = 0;
        end = 0;
      }
    }
    if (end > 0) {
      for (let i = start; i < end; i++) {
        agelist.push(i + 1);
      }
    }
    if (agelist.length > 0) {
      this.incidentListForm.controls.AgeGroup.setValue(agelist);
    } else {
      this.incidentListForm.controls.AgeGroup.setValue(null);
    }
  }
  selectedPage = 1;
  onChangeTable($event) {
    this.page.PageSize = $event.itemsPerPage;
    this.page.CurrentPage = $event.page;
    this.page.Total = this.total;
    this.searchlistOfIncident(false);
  }

  OnCummulativeSearchChange(value, frm) {
    if (frm == "isCummulative" && value == true) {
      this.showCummulativeSearch = true;
      if (this.isfilterShown) {
        for (var property in this.isfilterShown.ComulativeFilters) {
          if (!this.isfilterShown.ComulativeFilters.hasOwnProperty(property)) {
            continue;
          }
          this.cummulativeFilterNames.push(property);
        }
      }
    } else if (frm == "isCummulative" && value == false) {
      this.showCummulativeSearch = false;
      this.showSubCummulativeSearch = false;
    }
  }
  onPageSorted(event) {
    console.log(event);
    const rows = this.rows;
    const sort = event.sorts[0];

    rows.sort((a, b) => {
      if (isNumber(a[sort.prop])) {
        if (sort.dir == "asc") {
          return a[sort.prop] - b[sort.prop];
        } else {
          return b[sort.prop] - a[sort.prop];
        }
      } else {
        return (
          a[sort.prop].localeCompare(b[sort.prop]) *
          (sort.dir === "desc" ? -1 : 1)
        );
      }
    });
  }



  
  scrollLeft(): void {
    // Logic to scroll the table content to the left
    const currentScroll = this.datatable.element.getElementsByClassName('datatable-body')[0].scrollLeft;
    const newScroll = currentScroll - 90; // Change this value as needed
    this.datatable.element.getElementsByClassName('datatable-body')[0].scrollLeft = newScroll;
  }
 
  scrollRight(): void {
    // Logic to scroll the table content to the right
    const currentScroll = this.datatable.element.getElementsByClassName('datatable-body')[0].scrollLeft;
    const newScroll = currentScroll + 90; // Change this value as needed
    this.datatable.element.getElementsByClassName('datatable-body')[0].scrollLeft = newScroll;
  }
}
